<template>
  <div
    class="co-flex-col co-justify-center co-items-center co-w-full co-flex-grow"
  >
    <div
      class="co-flex-row"
    >
      <v-btn
        icon
        dark
        @click="onPreview"
      >
        <v-icon>
          mdi-eye-outline
        </v-icon>
      </v-btn>
      <v-btn
        icon
        dark
        class="ml-2"
        @click="onRemove"
      >
        <v-icon>
          mdi-trash-can-outline
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      },
      index: {
        type: Number,
        default: -1
      }
    },
    methods: {
      onPreview () {
        this.$emit('on-preview', this.index, this.item)
      },
      onRemove () {
        this.$emit('on-remove', this.index, this.item)
      }
    }
  }
</script>
